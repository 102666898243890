import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

import { createAlertState, TAlertStoreState } from "./alert";
import { createLayoutState, TLayoutState } from "./layout";
import {
  createMortgageRateState,
  TMortgageRateStoreState,
} from "./mortgageRate";
import { createProjectState, TProjectStoreState } from "./project";

import {
  createMortgageCalculatorState,
  TMortgageCalculatorStoreState,
} from "~/stores/mortgage-calculator";
import { createTrackingState, TTrackingStoreState } from "~/stores/tracking";

export const useAppState = create<
  TLayoutState &
  TProjectStoreState &
  TMortgageRateStoreState &
  TTrackingStoreState &
  TAlertStoreState &
  TMortgageCalculatorStoreState
>()(
  devtools(
    persist(
      (...state) => ({
        ...createLayoutState(...state),
        ...createProjectState(...state),
        ...createMortgageRateState(...state),
        ...createTrackingState(...state),
        ...createAlertState(...state),
        ...createMortgageCalculatorState(...state),
      }),
      {
        name: "user-storage",
        // Uncomment below line to use sessionStorage instead of localStorage
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              // Prevent modalState from being persisted
              ([key]) => ![""].includes(key)
            )
          ),
      }
    )
  )
);
